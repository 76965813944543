<template>
  <div class="card">
    <div class="card-header border-0 pt-6">
      <!--begin::Card title-->
      <div class="card-title">
        <!--begin::Search-->
        <div class="d-flex align-items-center position-relative my-1">
          <span class="svg-icon svg-icon-1 position-absolute ms-6">
            <inline-svg src="../media/icons/duotune/general/gen021.svg" />
          </span>
          <input
            type="text"
            v-model="search"
            @input="searchItems()"
            class="form-control form-control-solid w-250px ps-15"
            placeholder="Search Licensed Distributors"
          />
        </div>
        <!--end::Search-->
      </div>
      <!--begin::Card title-->
      <!--begin::Card toolbar-->
      <div class="card-toolbar">
        <!--begin::Toolbar-->
        <div
          class="d-flex justify-content-end"
          data-kt-customer-table-toolbar="base"
        >
          <!--begin::Add client-->
          <button
            type="button"
            class="btn btn-primary"
            data-bs-toggle="modal"
            data-bs-target="#modal_new_licensed_distributor_user"
          >
            <span class="svg-icon svg-icon-2">
              <inline-svg src="../media/icons/duotone/Navigation/Plus.svg" />
            </span>
            Add Licensed Distributor
          </button>
          <!--end::Add client-->
        </div>
        <!--end::Toolbar-->
      </div>
      <!--end::Card toolbar-->
    </div>
    <div class="card-body pt-0" v-if="isDataReady">
      <Datatable
        :table-data="tableData"
        :table-header="tableHeader"
        :enable-items-per-page-dropdown="true"
      >
        <template v-slot:cell-name="{ row: licensedDist }">
          <strong>{{ licensedDist.name }}</strong
          ><br /><small>{{ licensedDist.companyName }}</small
          ><br /><small>{{ licensedDist.jobTitle }}</small>
        </template>
        <template v-slot:cell-email="{ row: licensedDist }">
          <a
            :href="'mailto:' + licensedDist.email"
            class="text-gray-600 text-hover-primary mb-1"
          >
            {{ licensedDist.email }}
          </a>
        </template>
        <template v-slot:cell-numOfClients="{ row: licensedDist }">
          {{ licensedDist.numOfClients }}
        </template>
        <template v-slot:cell-numOfEmployees="{ row: licensedDist }">
          {{ licensedDist.numOfEmployees }}
        </template>
        <template v-slot:cell-emailConfirmed="{ row: licensedDist }">
          <span
            v-show="licensedDist.emailConfirmed"
            class="badge badge-success mx-2"
            >Verified</span
          ><span
            v-show="!licensedDist.emailConfirmed"
            class="badge badge-danger mx-2"
            >Not Verified</span
          >
        </template>
        <template v-slot:cell-archive="{ row: licensedDist }">
          <span v-show="!licensedDist.archive" class="badge badge-success mx-2"
            >Active</span
          ><span v-show="licensedDist.archive" class="badge badge-danger mx-2"
            >Deactivated</span
          >
        </template>
        <template v-slot:cell-hireStatusText="{ row: licensedDist }">
          {{ licensedDist.hireStatusText }}
        </template>
        <!--begin::Edit-->
        <template v-slot:cell-actions="{ row: licensedDist }">
          <router-link
            :to="{
              name: 'sa-licensed-distributor-clients',
              params: {
                accountid: String(licensedDist.distAspNetUserId),
              },
            }"
            class="
              btn btn-icon btn-bg-light btn-active-color-primary btn-sm
              me-1
            "
          >
            <span class="svg-icon svg-icon-3">
              <inline-svg src="../media/icons/duotune/art/art005.svg" /> </span
          ></router-link>

          <a
            v-show="!licensedDist.archive"
            href="#"
            class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
            @click="
              deleteLicensedDistributor(licensedDist.licensedDistributorId)
            "
          >
            <span class="svg-icon svg-icon-3">
              <inline-svg src="../media/icons/duotune/general/gen027.svg" />
            </span>
          </a>
        </template>
      </Datatable>
    </div>
    <div v-else>
      <TableSkeleton></TableSkeleton>
    </div>
  </div>
  <NewLicensedDistributorModal
    :displayModal="displayModal"
  ></NewLicensedDistributorModal>
</template>

<script lang="ts">
import {
  defineComponent,
  ref,
  onMounted,
  computed,
  onUpdated,
  watch,
} from "vue";
import Datatable from "@/components/datatable/KTDatatable.vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { Actions } from "@/store/enums/StoreEnums";
import { LicensedDistListItemDto } from "@/core/types/LicensedDistributorTypes";
import { useStore } from "vuex";
import NewLicensedDistributorModal from "@/components/modals/forms/NewLicensedDistributorModal.vue";
import TableSkeleton from "@/components/widgets/skeletons/TableSkeleton.vue";

export default defineComponent({
  name: "licensed-distributor-listing",
  components: {
    Datatable,
    NewLicensedDistributorModal,
    TableSkeleton,
  },
  setup() {
    const store = useStore();
    let displayModal = ref<boolean>(false);
    const isDataReady = ref(false);
    const tableHeader = ref([
      {
        name: "Name",
        key: "name",
        sortable: true,
      },
      {
        name: "Email",
        key: "email",
        sortable: true,
      },
      {
        name: "# of Associates",
        key: "numOfEmployees",
        sortable: true,
      },
      {
        name: "# of Clients",
        key: "numOfClients",
        sortable: true,
      },
      {
        name: "Email Confirmed",
        key: "emailConfirmed",
        sortable: true,
      },
      {
        name: "Status",
        key: "archive",
        sortable: true,
      },
      {
        name: "Employment",
        key: "hireStatusText",
        sortable: true,
      },
      {
        name: "Actions",
        key: "actions",
      },
    ]);

    const tableData = computed(() => {
      return store.getters.getLicensedDistributorList;
    });

    const initCustomers = ref<Array<LicensedDistListItemDto>>([]);

    store
      .dispatch(Actions.GET_LICENSED_DISTRIBUTORS)
      .then(() => {
        store
          .dispatch(
            Actions.GET_APPLICATION_USERS_BY_ROLE,
            "LicensedDistributor"
          )
          .catch(() => {
            Swal.fire({
              text: store.getters.getErrors[0],
              icon: "error",
              buttonsStyling: false,
              confirmButtonText:
                "There was a problem retrieving licensed distributor users.",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          });
      })
      .catch(() => {
        Swal.fire({
          text: store.getters.getErrors[0],
          icon: "error",
          buttonsStyling: false,
          confirmButtonText:
            "There was a problem retrieving licensed distributors.",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
      });

    onMounted(() => {
      //MenuComponent.reinitialization();
      setCurrentPageTitle("Licensed Distributors");
      setCurrentPageBreadcrumbs("Licensed Distributor List", ["Admin"]);
    });

    onUpdated(() => {
      initCustomers.value.splice(0, tableData.value.length, ...tableData.value);
    });

    watch(tableData, () => {
      isDataReady.value = true;
    });

    function openModal() {
      displayModal.value = true;
    }

    const deleteLicensedDistributor = (id) => {
      Swal.fire({
        title: "Are you sure you want to deactivate this Licensed Distributor?",
        showCancelButton: true,
        confirmButtonText: "Yes. Deactivate",
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          store
            .dispatch(Actions.DELETE_LICENSED_DISTRIBUTOR, id)
            .then(() => {
              Swal.fire("The account has been deactivated!", "", "success");
              for (let i = 0; i < tableData.value.length; i++) {
                if (tableData.value[i].licensedDistributorId === id) {
                  tableData.value.splice(i, 1);
                }
              }
            })
            .catch(() => {
              Swal.fire({
                text: store.getters.getErrors[0],
                icon: "error",
                buttonsStyling: false,
                confirmButtonText:
                  "There was a problem deactivating the licensed distributor.",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-danger",
                },
              });
            });
        }
      });
    };

    const search = ref<string>("");
    const searchItems = () => {
      tableData.value.splice(0, tableData.value.length, ...initCustomers.value);
      if (search.value !== "") {
        let results: Array<LicensedDistListItemDto> = [];
        for (let j = 0; j < tableData.value.length; j++) {
          if (searchingFunc(tableData.value[j], search.value)) {
            results.push(tableData.value[j]);
          }
        }
        tableData.value.splice(0, tableData.value.length, ...results);
      }
    };

    const searchingFunc = (obj, value): boolean => {
      /*for (let key in obj) {
        if (
          !Number.isInteger(obj[key]) &&
          !(typeof obj[key] === boolean) &&
          !(typeof obj[key] === "object")
        ) {
          if (obj[key].indexOf(value) != -1) {
            return true;
          }
        }
      }*/
      return false;
    };

    return {
      isDataReady,
      initCustomers,
      tableData,
      tableHeader,
      search,
      displayModal,
      deleteLicensedDistributor,
      searchItems,
      openModal,
    };
  },
});
</script>
