
import { defineComponent, ref, computed, ComputedRef } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { NewApplicationUser } from "../../../core/types/UserTypes";
import { LicensedDistributor } from "../../../core/types/LicensedDistributorTypes";
import { InclineEnum } from "../../../core/types/InclineEnumTypes";

export default defineComponent({
  name: "new-licensed-distributor-user-modal",
  props: ["displayModal"],
  components: { Field, Form, ErrorMessage },
  setup() {
    const store = useStore();

    const submitButton = ref<HTMLElement | null>(null);

    const modalRef = ref<null | HTMLFormElement>(null);

    const newApplicationUser = ref<NewApplicationUser>({
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      confirmPassword: "",
      userRole: "LicensedDistributor",
      licensedDistributor: true,
      activeAccount: true,
      hireStatusValue: 0,
      companyName: "",
      jobTitle: "",
    });

    const newLicensedDistributor = ref<LicensedDistributor>({
      licensedDistributorId: 0,
      name: "",
      distAspNetUserId: "",
      associateAspNetUserId: "NA",
      associate: false,
    });

    const registration = Yup.object().shape({
      firstName: Yup.string().required().label("First Name"),
      lastName: Yup.string().required().label("Last Name"),
      email: Yup.string().min(4).required().email().label("Email"),
      password: Yup.string().min(6).required().label("Password"),
      cpassword: Yup.string()
        .min(6)
        .required()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .label("Password Confirmation"),
      hireStatus: Yup.number()
        .min(5, "Hire Status is required")
        .required()
        .label("Hire Status"),
    });

    // Get Hire Status Enums
    const hiringStatuses: ComputedRef<Array<InclineEnum>> = computed(() => {
      return store.getters.getHiringStatuses;
    });
    store
      .dispatch(Actions.GET_INCLINE_HIRE_STATUS_ENUMS)
      .then(() => {
        //
      })
      .catch(() => {
        Swal.fire({
          text: store.getters.getErrors[0],
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "There was a problem retrieving hiring statuses.",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
      });

    const onSubmitRegister = () => {
      // Activate indicator
      submitButton.value?.setAttribute("data-kt-indicator", "on");

      // Send login request
      store
        .dispatch(Actions.CREATE_USER, newApplicationUser.value)
        .then((payload) => {
          (newLicensedDistributor.value.distAspNetUserId = payload.id),
            (newLicensedDistributor.value.name =
              payload.firstName + " " + payload.lastName),
            store
              .dispatch(
                Actions.GET_LICENSED_DISTRIBUTOR_LISTITEM,
                newLicensedDistributor.value.distAspNetUserId
              )
              .then(() => {
                Swal.fire({
                  text: "Success. The new Licensed Distributor has been created.",
                  icon: "success",
                  buttonsStyling: false,
                  confirmButtonText: "Continue",
                  customClass: {
                    confirmButton: "btn fw-bold btn-light-primary",
                  },
                }).then(function () {
                  resetForm();
                  modalRef.value?.click();
                });
              })
              .catch(() => {
                Swal.fire({
                  text: store.getters.getErrors[0],
                  icon: "error",
                  buttonsStyling: false,
                  confirmButtonText:
                    "There was an issue creating the Licensed Distributor. Try again!",
                  customClass: {
                    confirmButton: "btn fw-bold btn-light-danger",
                  },
                });
              });
        })
        .catch(() => {
          Swal.fire({
            text: store.getters.getErrors[0],
            icon: "error",
            buttonsStyling: false,
            confirmButtonText:
              "There was an issue creating the account. Try again!",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        });

      submitButton.value?.removeAttribute("data-kt-indicator");
    };

    function closeModal() {
      modalRef.value?.click();
      resetForm();
    }

    function resetForm() {
      newApplicationUser.value = {
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        confirmPassword: "",
        userRole: "LicensedDistributor",
        licensedDistributor: true,
        activeAccount: true,
        hireStatusValue: 0,
        companyName: "",
        jobTitle: "",
      };
    }

    return {
      newApplicationUser,
      hiringStatuses,
      newLicensedDistributor,
      closeModal,
      resetForm,
      modalRef,
      registration,
      onSubmitRegister,
      submitButton,
    };
  },
});
