<template>
  <!--begin::Modal - New Target-->
  <div
    class="modal fade"
    id="modal_new_licensed_distributor_user"
    tabindex="-1"
    aria-hidden="true"
  >
    <!--begin::Modal dialog-->
    <div class="modal-dialog modal-dialog-centered mw-650px">
      <!--begin::Modal content-->
      <div class="modal-content rounded">
        <!--begin::Modal header-->
        <div class="modal-header pb-0 border-0 justify-content-end">
          <!--begin::Close-->
          <div
            class="btn btn-sm btn-icon btn-active-color-primary"
            data-bs-dismiss="modal"
            ref="modalRef"
            @click="closeModal()"
          >
            <span class="svg-icon svg-icon-1">
              <inline-svg src="/media/icons/duotune/arrows/arr061.svg" />
            </span>
          </div>
          <!--end::Close-->
        </div>
        <!--begin::Modal header-->

        <!--begin::Modal body-->
        <div class="modal-body scroll-y px-10 px-lg-15 pt-0 pb-15">
          <!--begin:Form-->
          <Form
            class="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
            novalidate="novalidate"
            @submit="onSubmitRegister"
            id="new_licensed_distributor_user_form"
            :validation-schema="registration"
          >
            <!--begin::Heading-->
            <div class="mb-13 text-center">
              <!--begin::Title-->
              <h1 class="mb-3">Create New Licensed Distributor</h1>
              <!--end::Title-->
            </div>
            <!--end::Heading-->

            <!--begin::Input group-->
            <div class="row g-9 mb-8">
              <!--begin::Col-->
              <div class="col-md-6 fv-row">
                <label class="required fs-6 fw-bold mb-2">First Name</label>

                <Field
                  class="form-control form-control-lg form-control-solid"
                  type="text"
                  placeholder=""
                  name="firstName"
                  autocomplete="off"
                  v-model="newApplicationUser.firstName"
                />
                <div class="fv-plugins-message-container">
                  <div class="fv-help-block">
                    <ErrorMessage name="firstName" />
                  </div>
                </div>
              </div>
              <!--end::Col-->
              <!--begin::Col-->
              <div class="col-md-6 fv-row">
                <label class="required fs-6 fw-bold mb-2">Last Name</label>

                <Field
                  class="form-control form-control-lg form-control-solid"
                  type="text"
                  placeholder=""
                  name="lastName"
                  autocomplete="off"
                  v-model="newApplicationUser.lastName"
                />
                <div class="fv-plugins-message-container">
                  <div class="fv-help-block">
                    <ErrorMessage name="lastName" />
                  </div>
                </div>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Input group-->
            <!--begin::Input group-->
            <div class="d-flex flex-column mb-8 fv-row">
              <!--begin::Label-->
              <label class="d-flex align-items-center fs-6 fw-bold mb-2">
                <span>Company Name</span>
              </label>
              <!--end::Label-->

              <Field
                class="form-control form-control-lg form-control-solid"
                type="text"
                placeholder=""
                name="companyName"
                autocomplete="off"
                v-model="newApplicationUser.companyName"
              />
            </div>
            <!--end::Input group-->
            <!--begin::Input group-->
            <div class="d-flex flex-column mb-8 fv-row">
              <!--begin::Label-->
              <label class="d-flex align-items-center fs-6 fw-bold mb-2">
                <span>Job Title</span>
              </label>
              <!--end::Label-->

              <Field
                class="form-control form-control-lg form-control-solid"
                type="text"
                placeholder=""
                name="jobTitle"
                autocomplete="off"
                v-model="newApplicationUser.jobTitle"
              />
            </div>
            <!--end::Input group-->

            <!--begin::Input group-->
            <div class="d-flex flex-column mb-8 fv-row">
              <!--begin::Label-->
              <label class="d-flex align-items-center fs-6 fw-bold mb-2">
                <span class="required">Email</span>
              </label>
              <!--end::Label-->

              <Field
                class="form-control form-control-lg form-control-solid"
                type="email"
                placeholder=""
                name="email"
                autocomplete="off"
                v-model="newApplicationUser.email"
              />
              <div class="fv-plugins-message-container">
                <div class="fv-help-block">
                  <ErrorMessage name="email" />
                </div>
              </div>
            </div>
            <!--end::Input group-->
            <!--begin::Input group-->
            <div class="row g-9 mb-8">
              <!--begin::Col-->
              <div class="col-md-6 fv-row">
                <label class="required fs-6 fw-bold mb-2">Password</label>

                <Field
                  class="form-control form-control-lg form-control-solid"
                  type="password"
                  placeholder=""
                  name="password"
                  autocomplete="off"
                  v-model="newApplicationUser.password"
                />
                <div class="fv-plugins-message-container">
                  <div class="fv-help-block">
                    <ErrorMessage name="password" />
                  </div>
                </div>
              </div>
              <!--end::Col-->
              <!--begin::Col-->
              <div class="col-md-6 fv-row">
                <label class="required fs-6 fw-bold mb-2"
                  >Confirm Password</label
                >
                <Field
                  class="form-control form-control-lg form-control-solid"
                  type="password"
                  placeholder=""
                  name="cpassword"
                  autocomplete="off"
                  v-model="newApplicationUser.confirmPassword"
                />
                <div class="fv-plugins-message-container">
                  <div class="fv-help-block">
                    <ErrorMessage name="cpassword" />
                  </div>
                </div>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Input group-->
            <!--begin::Input group-->
            <div class="row g-9 mb-8">
              <!--begin::Col-->
              <div class="col-md-6 fv-row">
                <label class="required fs-6 fw-bold mb-2">Hire Status</label>
                <Field
                  name="hireStatus"
                  class="form-select form-select-solid"
                  as="select"
                  v-model="newApplicationUser.hireStatusValue"
                >
                  <option value="0">Select a Status...</option>
                  <option
                    v-for="status in hiringStatuses"
                    :key="status.value"
                    :label="status.text"
                    :value="status.value"
                  >
                    {{ status.text }}
                  </option>
                </Field>
                <div class="fv-plugins-message-container">
                  <div class="fv-help-block">
                    <ErrorMessage name="hireStatus" />
                  </div>
                </div>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Input group-->
            <!--begin::Actions-->
            <div class="text-center">
              <button
                type="reset"
                id="kt_modal_new_ld_cancel"
                class="btn btn-white me-3"
                data-bs-dismiss="modal"
                @click="resetForm()"
              >
                Cancel
              </button>

              <button
                id="kt_sign_up_submit"
                ref="submitButton"
                type="submit"
                class="btn btn-lg btn-primary"
              >
                <span class="indicator-label"> Submit </span>
                <span class="indicator-progress">
                  Please wait...
                  <span
                    class="spinner-border spinner-border-sm align-middle ms-2"
                  ></span>
                </span>
              </button>
            </div>
            <!--end::Actions-->
          </Form>
          <!--end:Form-->
        </div>
        <!--end::Modal body-->
      </div>
      <!--end::Modal content-->
    </div>
    <!--end::Modal dialog-->
  </div>
  <!--end::Modal - New Target-->
</template>

<script lang="ts">
import { defineComponent, ref, computed, ComputedRef } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { NewApplicationUser } from "../../../core/types/UserTypes";
import { LicensedDistributor } from "../../../core/types/LicensedDistributorTypes";
import { InclineEnum } from "../../../core/types/InclineEnumTypes";

export default defineComponent({
  name: "new-licensed-distributor-user-modal",
  props: ["displayModal"],
  components: { Field, Form, ErrorMessage },
  setup() {
    const store = useStore();

    const submitButton = ref<HTMLElement | null>(null);

    const modalRef = ref<null | HTMLFormElement>(null);

    const newApplicationUser = ref<NewApplicationUser>({
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      confirmPassword: "",
      userRole: "LicensedDistributor",
      licensedDistributor: true,
      activeAccount: true,
      hireStatusValue: 0,
      companyName: "",
      jobTitle: "",
    });

    const newLicensedDistributor = ref<LicensedDistributor>({
      licensedDistributorId: 0,
      name: "",
      distAspNetUserId: "",
      associateAspNetUserId: "NA",
      associate: false,
    });

    const registration = Yup.object().shape({
      firstName: Yup.string().required().label("First Name"),
      lastName: Yup.string().required().label("Last Name"),
      email: Yup.string().min(4).required().email().label("Email"),
      password: Yup.string().min(6).required().label("Password"),
      cpassword: Yup.string()
        .min(6)
        .required()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .label("Password Confirmation"),
      hireStatus: Yup.number()
        .min(5, "Hire Status is required")
        .required()
        .label("Hire Status"),
    });

    // Get Hire Status Enums
    const hiringStatuses: ComputedRef<Array<InclineEnum>> = computed(() => {
      return store.getters.getHiringStatuses;
    });
    store
      .dispatch(Actions.GET_INCLINE_HIRE_STATUS_ENUMS)
      .then(() => {
        //
      })
      .catch(() => {
        Swal.fire({
          text: store.getters.getErrors[0],
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "There was a problem retrieving hiring statuses.",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
      });

    const onSubmitRegister = () => {
      // Activate indicator
      submitButton.value?.setAttribute("data-kt-indicator", "on");

      // Send login request
      store
        .dispatch(Actions.CREATE_USER, newApplicationUser.value)
        .then((payload) => {
          (newLicensedDistributor.value.distAspNetUserId = payload.id),
            (newLicensedDistributor.value.name =
              payload.firstName + " " + payload.lastName),
            store
              .dispatch(
                Actions.GET_LICENSED_DISTRIBUTOR_LISTITEM,
                newLicensedDistributor.value.distAspNetUserId
              )
              .then(() => {
                Swal.fire({
                  text: "Success. The new Licensed Distributor has been created.",
                  icon: "success",
                  buttonsStyling: false,
                  confirmButtonText: "Continue",
                  customClass: {
                    confirmButton: "btn fw-bold btn-light-primary",
                  },
                }).then(function () {
                  resetForm();
                  modalRef.value?.click();
                });
              })
              .catch(() => {
                Swal.fire({
                  text: store.getters.getErrors[0],
                  icon: "error",
                  buttonsStyling: false,
                  confirmButtonText:
                    "There was an issue creating the Licensed Distributor. Try again!",
                  customClass: {
                    confirmButton: "btn fw-bold btn-light-danger",
                  },
                });
              });
        })
        .catch(() => {
          Swal.fire({
            text: store.getters.getErrors[0],
            icon: "error",
            buttonsStyling: false,
            confirmButtonText:
              "There was an issue creating the account. Try again!",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        });

      submitButton.value?.removeAttribute("data-kt-indicator");
    };

    function closeModal() {
      modalRef.value?.click();
      resetForm();
    }

    function resetForm() {
      newApplicationUser.value = {
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        confirmPassword: "",
        userRole: "LicensedDistributor",
        licensedDistributor: true,
        activeAccount: true,
        hireStatusValue: 0,
        companyName: "",
        jobTitle: "",
      };
    }

    return {
      newApplicationUser,
      hiringStatuses,
      newLicensedDistributor,
      closeModal,
      resetForm,
      modalRef,
      registration,
      onSubmitRegister,
      submitButton,
    };
  },
});
</script>
